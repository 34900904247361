<bc-modal [customWidth]="'small'" [isCancellable]='isCancellable' [hideCloseButton]="isCloseButtonHidden"
    [outerCloseTap]="isOuterClose" [textBtn]="txButtonRight" (stateClick)="closeModal($event)" #errorModal
    [textBtnLeft]="txButtonLeft" [typeIcon]="iconType">
    <div modalContent class="aling-center">
        <img *ngIf="iconType == 'default'" class="img" alt="random-image" [src]="urlIconDefault">
        <h4>{{txTitle}}</h4>
        <div class="text-center text-subtitle" *ngIf="errorsShow.length === 0" [innerHTML]="txSubtitle"></div>
        <div class="modal-body">
            <span *ngFor="let error of errorsShow">
                Código de error: <strong>{{error.code}}</strong>-Código técnico: <strong>{{error.code}}</strong><br>
                Mensaje: {{error.detail}}
            </span>
        </div>
    </div>
</bc-modal>
