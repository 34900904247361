<div class="notifi-item" *ngFor="let item of notifications">
    <div class="description">
        <bc-icon style="padding: 3px;" height="30px" aria-hidden="true" aria-label="prueba">{{getIcon(item.modulo)}}</bc-icon>
        <div class="text">
            <h4>{{item.modulo}} {{item.accion}} </h4>
            <p>{{item.descripcion}}</p>
        </div>
    </div> 
    <div class="dateNotification">
        {{item.fecha}}
    </div>
</div>
