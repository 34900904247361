import { Component, OnInit } from '@angular/core';
import { TWO } from 'src/app/core/model/cardinal-numbers';
import { NotificationsService } from 'src/app/core/services/shared/notifications.service';
import { CapitalizePipe } from 'src/app/shared/pipes/capitalize/capitalize.pipe';

@Component({
    selector: 'app-item-notification',
    templateUrl: './item-notification.component.html',
    styleUrls: ['./item-notification.component.css']
})
export class ItemNotifications implements OnInit {
    name: string;
    box:any  = document.getElementById('box');
    down = false;

    notifications: any[] = [];

    constructor(private service: NotificationsService, private capitalize: CapitalizePipe) {
    }

    ngOnInit() {
        this.service.getNotifications()
        .subscribe(res =>{
            this.notifications = res.data.notifications.map(item => {
                item.accion = (item.accion == 'actualizado')? 'actualizada': item.accion;
                item.accion = (item.accion == 'eliminado')? 'eliminada': item.accion;
                item.accion = (item.accion == 'creado')? 'nueva': item.accion;
                item.accion = (item.accion == 'nuevo')? 'nueva': item.accion;
                item.accion = ((item.modulo == 'simuladores' || item.modulo == 'formato apoyo') && item.accion == 'nueva')? 'nuevo': item.accion;
                item.accion = ((item.modulo == 'simuladores' || item.modulo == 'formato apoyo') && item.accion == 'eliminada')? 'eliminado': item.accion;
                item.accion = ((item.modulo == 'simuladores' || item.modulo == 'formato apoyo') && item.accion == 'actualizada')? 'actualizado': item.accion;
                item.modulo = (item.modulo == 'noticias')? 'Noticia': item.modulo;
                item.modulo = (item.modulo == 'guias paso paso')? 'Guia paso a paso': item.modulo;
                item.modulo = (item.modulo == 'ayudaventas')? 'Ayuda ventas': item.modulo;
                item.modulo = (item.modulo == 'simuladores')? 'Simulador': item.modulo;
                item.modulo = (item.modulo == 'formato apoyo')? 'Formato de apoyo': item.modulo;
                item.modulo = (item.modulo == 'ayuda sistema')? 'Ayuda del sistema': item.modulo;
                item.modulo = (item.modulo == 'punto atencion')? 'Punto de atención' : item.modulo;
                
                item.descripcion = this.capitalize.transform(item.descripcion.split(' ').slice(TWO).join(' '));

                return item;
            });
        }, err =>{
            console.error(err);
        });
    }
    
    getIcon(modulo: string) : string{
        let icono:string  = 'idea';
        switch(modulo){
            case 'ayudaventas':
                icono = 'document-search';
                break;
            case 'ayuda sistema':
                icono = 'help';
                break;
            case 'simuladores':
                icono = 'calculator';
                break;
            case 'guias paso paso':
                icono = 'filters';
                break;
            case 'formato apoyo':
                icono = 'data';
                break;
            case 'punto atencion':
                icono = 'location';
                break;
            case 'parametros':
                icono = 'settings';
                break;
        }
        return icono;
    }
}
