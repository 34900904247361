import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private lckey = 'b!fRBcK&gZ%y';
    public glNmbAccount = '758"#gA';
    public glNmbUser = '684de977';
    public glNmbRol = '244fe621';

    setLocalStorage(nombre: string, data: string) {
        localStorage.removeItem(nombre);
        const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            this.lckey
        );
        localStorage.setItem(nombre, ciphertext.toString());
    }

    getLocalStorage(nombre: string) {
        if (localStorage.getItem(nombre) != null) {
            const bytes = CryptoJS.AES.decrypt(
                localStorage.getItem(nombre),
                this.lckey
            );
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return JSON.parse(decryptedData);
        }
    }

    removeItem(nombre: string) {
        if (localStorage.getItem(nombre) != null) {
            localStorage.removeItem(nombre);
        }
    }
}
