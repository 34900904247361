import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {AuthenticationResult, InteractionRequiredAuthError} from '@azure/msal-common';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService {

    constructor(private autenticacionService: MsalService) {
    }

    acquireToken() {
        return new Promise(resolve => {
            this.autenticacionService.acquireTokenSilent(this.getAccessToken()).subscribe((response: AuthenticationResult) => {
                    resolve(response.idToken);
                }, (error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        this.autenticacionService.acquireTokenPopup(this.getAccessToken()).subscribe((response: AuthenticationResult) => {
                            resolve(response.idToken);
                        });
                    }
                }
            );
        });
    }

    private getAccessToken() {
        const account = this.autenticacionService.instance.getActiveAccount();
        return {
            scopes: ['user.read'],
            account: account
        };
    }
}
