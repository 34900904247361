import {Injectable} from '@angular/core';
import {Router, CanLoad} from '@angular/router';
import {StorageService} from '../../../core/services/helpers/storage.service';

@Injectable()
export class AdminRoleGuardService implements CanLoad {
    constructor(public router: Router, private storageService: StorageService) {
    }

    canLoad(): boolean {
        const token = this.storageService.getLocalStorage(this.storageService.glNmbRol);
        if (token !== 'admin') {
            this.router.navigate(['/inicio']);
            return false;
        } else {
            return true;
        }
    }
}
