import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StorageService} from 'src/app/core/services/helpers/storage.service';
import { NotificationsService } from 'src/app/core/services/shared/notifications.service';
import { CapitalizePipe } from '../../pipes/capitalize/capitalize.pipe';

@Component({
    selector: 'app-header-bc',
    templateUrl: './header-bc.component.html',
    styleUrls: ['./header-bc.component.css']
})
export class HeaderBcComponent implements OnInit {
    name: string;
    role: string;
    @Output() openNotifications = new EventEmitter();
    @Input() showNotification: boolean = false;
    @Input() notificationsNumber: number = 0;
    constructor(private storageService: StorageService, public notificationsService: NotificationsService, private capitalize: CapitalizePipe) {
    }

    ngOnInit() {
        this.name = this.storageService.getLocalStorage(this.storageService.glNmbUser).name;
        this.role = this.capitalize.transform(this.storageService.getLocalStorage(this.storageService.glNmbRol) == 'admin'? 'administrador': this.storageService.getLocalStorage(this.storageService.glNmbRol));
        this.notificationsService.getNotifications().subscribe();
    }
    toggleNotifi(){
        this.showNotification = !this.showNotification;
        this.openNotifications.emit(this.showNotification);
    }
}
