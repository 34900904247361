import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {TextMaskModule} from 'angular2-text-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorModalComponent} from './error-modal/error-modal.component';
import {AuthBlockingComponent} from './auth-blocking/auth-blocking.component';
import {HeaderBcComponent} from './header-bc/header-bc.component';
import {MatSharedModule} from '../mat-shared/mat-shared.module';
import {BcSharedModule} from '../bc-shared/bc-shared.module';
import {InputSelectComponent} from './input-select/input-select.component';
import {InputSelectFilterComponent} from './input-select-filter/input-select-filter.component';
import {ModalNotifications} from './notifications/modal-notifications/modal.component';
import {ItemNotifications} from './notifications/item-notifications/item-notification.component';
import {PersonInformationComponent} from './person-information/person-information.component';
import {DirectivesModule} from '../directives/directives.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { NotificationsService } from 'src/app/core/services/shared/notifications.service';
import { OutOfTimeComponent } from './out-of-time/out-of-time.component';
import { CapitalizePipe } from '../pipes/capitalize/capitalize.pipe';
import { PepInfoMessageComponent } from './pep-info-message/pep-info-message.component';
import { LegalPersonInformationComponent } from './legal-person-information/legal-person-information.component';
import { ClientValidationComponent } from '../components/client-validation/client-validation.component';
import { ViewnewsComponent } from '../components/viewnews/viewnews.component';

@NgModule({
    declarations: [
        DatepickerComponent,
        ErrorModalComponent,
        AuthBlockingComponent,
        HeaderBcComponent,
        InputSelectComponent,
        InputSelectFilterComponent,
        ModalNotifications,
        ItemNotifications,
        PersonInformationComponent,
        SpinnerComponent,
        OutOfTimeComponent,
        PepInfoMessageComponent,
        LegalPersonInformationComponent,
        ClientValidationComponent,
        ViewnewsComponent
    ],
    imports: [
        CommonModule,
        TextMaskModule,
        FormsModule,
        ReactiveFormsModule,
        BcSharedModule,
        MatSharedModule,
        DirectivesModule
    ],
    exports: [
        CommonModule,
        DatepickerComponent,
        ErrorModalComponent,
        AuthBlockingComponent,
        HeaderBcComponent,
        InputSelectComponent,
        ModalNotifications,
        ItemNotifications,
        InputSelectFilterComponent,
        PersonInformationComponent,
        SpinnerComponent,
        PepInfoMessageComponent,
        LegalPersonInformationComponent,
        ClientValidationComponent,
        ViewnewsComponent
    ],
    providers:[
        NotificationsService,
        CapitalizePipe,
    ]
})
export class CommonsModule {
}
