import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MarkAsteriskDirective} from './ mark-asterisk/mark-asterisk.directive';
import {ClickOutsideDirective} from './clickOutside/clickOutside.directive';
import {ValidateInputDirective} from './validate-input/validate-input.directive';

@NgModule({
    declarations: [
        MarkAsteriskDirective,
        ClickOutsideDirective,
        ValidateInputDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MarkAsteriskDirective,
        ClickOutsideDirective,
        ValidateInputDirective
    ]
})
export class DirectivesModule {
}
