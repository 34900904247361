import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import menuRol from '../assets/jsons/menuRol.json';
import {MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration} from '@azure/msal-angular';
import {PopupRequest} from '@azure/msal-browser';
import {Observable, Subject, timer} from 'rxjs';
import {StorageService} from './core/services/helpers/storage.service';
import {User} from './core/model/products/products.interface';
import {FIFTY, FIVE_HUNDRED, THREE, THREE_THOUSAND_SIX_HUNDRED, TWO} from './core/model/cardinal-numbers';
import { map, shareReplay, debounceTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import Holidays from 'date-holidays';
import { HttpClient } from '@angular/common/http';
import { endpoint } from 'src/environments/environment';
import { NewsService } from './core/services/modules/news/news.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewnewsComponent } from './shared/components/viewnews/viewnews.component';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "appSegurosFront";
  isIframe = false;
  loginDisplay = false;
  menu = menuRol.menu;
  private readonly destroying = new Subject<void>();
  showNotification = false;
  menuDinamic: any = [];
  clock: Observable<Date>;
  hour: string = "";
  startHour: string = "07:00:00";
  endHour: string = "20:00:00";
  alertHour: string = "19:45:00";
  pipe = new DatePipe("en-US");
  titleError: string;
  descriptionError: string;
  textButtonRight: string;
  unerror: boolean;
  errorsShow: any[] = [];
  closeSesion: boolean = false;
  private rol: string;
  private userActivity;
  private userInactive: Subject<any> = new Subject();
  endpointurl = endpoint.urlSeguros;

    constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
                private authService: MsalService,
                private elementRef: ElementRef,
                private storageService: StorageService,
                private http: HttpClient,
                // private newsService: NewsService,
                // private matDialog: MatDialog,
                private router: Router) {
        this.startTimer();
        this.userInactive.subscribe(() => {
            this.logout();
        });
        this.clock = timer(0,1000).pipe(map(t => new Date()),shareReplay(1));
    }
    ngOnInit() {
        this.updateMenuRol(this.storageService.getLocalStorage(this.storageService.glNmbRol));
        this.removeAngularVerionOfTheDOM();
        this.isIframe = window !== window.parent && !window.opener;
        this.setLoginDisplay();
        if (!this.loginDisplay) {
            localStorage.clear();
            this.loginPopup();
        }
        this.validateRole();
        // this.modalNews();
    }
    validateRole() {
        /* Validación del cierre de sesión */
        if (this.rol && (this.rol == 'asesor' || this.rol == 'solucionador')) {
            this.timerCloseSession();
        }
    }
    timerCloseSession() {
        const hd = new Holidays('CO');
        const fechaActual = new Date();
        const isHd = hd.isHoliday(fechaActual);
        this.clock.pipe(debounceTime(FIVE_HUNDRED)).subscribe(t => {
            this.hour = this.pipe.transform(t, 'HH:mm:ss');
            if (this.hour == this.alertHour) {
                this.unerror = true;
                this.showErrorModal("¡Atención!", "Recuerda que solo tendrás el SIS disponible hasta las 8:00pm. Aprovecha al máximo estos 15 minutos.");
            }
            if (this.hour >= this.endHour || this.hour <= this.startHour || isHd != false || fechaActual.getDay() == 0) {
                this.closeSesion = true;
                this.router.navigate(['cerrar']);
            } else {
                this.closeSesion = false;
                this.router.navigate([this.router.url == '/cerrar' ? 'inicio' : this.router.url]);
            }
        });
    }

    // private async modalNews() {
    //     let news = [];
    //     let newPostDate: null, newPostDateMod: null;
    //     const data: any = await this.newsService.getNewsObs().toPromise();
    //     try {
    //         console.log(Date.now());
    //         if (data.status == "success") {
    //             news = data.data.news;
    //             news.sort((a, b) => {
    //                 return (new Date(b.postDateMod ? b.postDateMod : b.postDate).getTime() -
    //                     new Date(a.postDateMod ? a.postDateMod : a.postDate).getTime());
    //                 });
    //                 newPostDate = news[0].postDate;
    //                 newPostDateMod = news[0].postDateMod;
    //                 newPostDateMod !== null? this.validDateModal(newPostDateMod, news[0]): this.validDateModal(newPostDate, news[0]);
    //         } else {
    //             this.showErrorModal("Error cargando noticias!!", data.message);
    //         }
    //     } catch (error) {
    //         this.showErrorModal("Error cargando noticias!!", error.message);
    //     }
    //     // await this.newsService.getNewsObs().toPromise((data:any) => {
    //     // }).catch(error => {
    //     //     this.showErrorModal("Error cargando noticias!!", error.message);
    //     // });
    // }

    // private validDateModal(dateNew: string, news: any) {
    //     const fechaActual = new Date().toISOString();
    //     let dateNewInit = new Date(dateNew).toISOString();
    //     let dateNewEnd = this.endDateNewsNotify(dateNewInit);
    //     if(fechaActual >= dateNew && fechaActual <= dateNewEnd ) {
    //         this.showNoticiasNews(news);
    //     }
    // }
    ngOnDestroy(): void {
        this.destroying.next(null);
        this.destroying.complete();
    }
    logout = (popup?: boolean) => {
        if (popup) {
            this.authService.logoutPopup({
                mainWindowRedirectUri: '/'
            });
        } else {
            const endPointLogout = `${this.endpointurl}logout`;
            this.http.delete(endPointLogout).subscribe(() => {
                this.authService.logoutRedirect();
            });
        }
        localStorage.clear();
    };
    eventNotification(event) {
        setTimeout(() => {
            this.showNotification = event;
        }, FIFTY);
    }
    closeNotification() {
        this.showNotification = false;
    }
    updateMenuRol(rol: string) {
        this.rol=rol;
        if (rol === 'asesor') {
            this.menuDinamic = this.menu.asesor;
        } else if (rol === 'solucionador') {
            this.menuDinamic = this.menu.solucionador;
        } else if (rol === 'admin') {
            this.menuDinamic = this.menu.admin;
        }
    }
    removeAngularVerionOfTheDOM() {
        this.elementRef.nativeElement.removeAttribute('ng-version');
    }
    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }
    loginPopup() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest).subscribe((response: any) => {
                this.loginSubscribe(response);
            });
        } else {
            this.authService.loginPopup().subscribe((response: any) => {
                this.loginSubscribe(response);
            });
        }
    }
    loginSubscribe(response: any) {
        this.authService.instance.setActiveAccount(response.account);
        this.setLoginDisplay();
        if (this.loginDisplay) {
            localStorage.clear();
            let redUser: string = response.account.username;
            redUser = redUser.split('@')[0].toUpperCase();
            const user: User = {
                redUser,
                name: response.account.name
            };
            const rol = response.account.idTokenClaims.roles[0];
            this.storageService.setLocalStorage(this.storageService.glNmbUser, JSON.stringify(user));
            this.storageService.setLocalStorage(this.storageService.glNmbAccount, JSON.stringify(response.account));
            this.storageService.setLocalStorage(this.storageService.glNmbRol, JSON.stringify(rol));
            this.updateMenuRol(rol);
            this.validateRole();
            this.router.navigate(['inicio']);
        }
    }
    private startTimer() {
        const waitTime = 1140000; // 19 minutos
        const waitTimePopup = 60000; // 1 minuto
        this.userActivity = setTimeout(() => {
            this.showErrorModal("No hemos detectado actividad en los últimos 19 minutos", "Tu sesión caducará en 1 minuto");
            this.userActivity = setTimeout(() => {
                this.userInactive.next();
            }, waitTimePopup)
        }, waitTime);
    }
    @HostListener('document:click')
    @HostListener('document:scroll')
    @HostListener('document:mousemove')
    @HostListener('document:keyup') resetTimer() {
        clearTimeout(this.userActivity);
        this.startTimer();
    }
    showErrorModal(title: string, description: string) {
        this.titleError = title;
        this.textButtonRight = 'Entendido';
        this.descriptionError = description;
        this.unerror = true;
    }
    // showNoticiasNews(news: any) {
    //     this.matDialog.open(ViewnewsComponent, {
    //        width: '50%', height:'auto',
    //        data: {view: news} 
    //     });
    // }
    // endDateNewsNotify(dateNew: string): string{
    //     const hd = new Holidays('CO');
    //     let dateNewInit = new Date(dateNew);
    //     let i = THREE - THREE;
    //     while (i < THREE){
    //         dateNewInit.setDate(dateNewInit.getDate() + 1);
    //         if(dateNewInit.getDay() !== 0 && hd.isHoliday(dateNewInit) === false)
    //             i++;
    //     }
    //     return dateNewInit.toISOString();
    // }
}
