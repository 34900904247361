import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
    enableProdMode();
    if (typeof (window.console) !== undefined) {
        window.console.log = window.console.error = window.console.info = window.console.warn = window.console.trace = () => {
            // funcion vacia.
        };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
