import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsPipe } from './accounts/accounts.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';


@NgModule({
  declarations: [
    AccountsPipe,
    CapitalizePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AccountsPipe,
    CapitalizePipe
  ]
})
export class PipesModule { }
