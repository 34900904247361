<bc-header>
    <bc-header-left logoMobile="" logoName="grupo-bancolombia-horizontal" txtLeftMenu="" channelName=" ">
    </bc-header-left>

    <div class="bc-header-nav-item-text alignHeader">
        <h5 class="fontHeader">SIS | Sistema integral de seguros</h5><small class="fontSmallHeader">* Este es un aplicativo de uso interno para empleados Bancolombia</small>
    </div>
    <bc-header-list>
            <div class="bc-link">
                <em class="bc-icon bc-sm">user</em>
                <div class="bc-header-nav-item-text">
                    <p>{{name}}</p><small>{{role}}</small>
                </div>
            </div>
        <div class="iconNotificacions">
            <bc-header-item itemtype="user" id="bc-header-item-notification" icon="notification" (click)="toggleNotifi()" style="cursor: pointer;" text="Notificaciones"></bc-header-item>
            <span class="spanNotifications">{{notificationsService.currentNotifications}}</span>
        </div>
    </bc-header-list>
</bc-header>