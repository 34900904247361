import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {endpoint} from '../../../../environments/environment';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    lastendpoint = endpoint.endpointParametrizationProd;
    notifications: any[] = [];
    currentNotifications = 0;

    constructor(public http: HttpClient) {
    }

    getNotifications() {
        return this.http.get(this.lastendpoint + 'notifications').pipe(tap((res: any) => {
            if (res.status === 'success') {
                this.notifications = this.formatDate(res.data.notifications);
            } else {
                throw(res);
            }
        }));
    }

    formatDate(data) {
        this.currentNotifications = 0;
        data.map((elem) => {
            if (moment(new Date(), 'YYYY-MM-DD').diff(elem.fecha, 'days') === 0) {
                this.currentNotifications = this.currentNotifications + 1;
            }
            elem.fecha = elem.fecha.split('-').reverse().join('/');
        });
        return data;
    }

    getNotificationsJson() {
        return this.http.get('assets/jsons/notifications.json').pipe(tap((res: any) => {
            if (res.status === 'success') {
                this.currentNotifications = 0;
                this.notifications = this.formatDate(res.data.notifications);
            } else {
                throw(res);
            }
        }));
    }
}
