import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-modal-notifications',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalNotifications {

    name: string;
    box: any = document.getElementById('box');
    down = false;
    @Output() click = new EventEmitter();

    toggleNotifi() {
        if (this.down) {
            this.box.style.height = '0px';
            this.box.style.opacity = 0;
            this.down = false;
        } else {
            this.box.style.height = '510px';
            this.box.style.opacity = 1;
            this.down = true;
        }
    }
}
