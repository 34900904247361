import { NgModule } from '@angular/core';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { BcBreadcrumbModule } from '@bancolombia/design-system-web/bc-breadcrumb';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcCalendarModule } from '@bancolombia/design-system-web/bc-calendar';
import { BcCardModule } from '@bancolombia/design-system-web/bc-card';
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox';
import { BcFooterModule } from '@bancolombia/design-system-web/bc-footer';
import { BcHeaderModule } from '@bancolombia/design-system-web/bc-header';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcInputDateModule } from '@bancolombia/design-system-web/bc-input-date';
import { BcInputDateRangeModule } from '@bancolombia/design-system-web/bc-input-date-range';
import { BcInputFileModule } from '@bancolombia/design-system-web/bc-input-file';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcLoaderModule } from '@bancolombia/design-system-web/bc-loader';
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcPaginatorModule } from '@bancolombia/design-system-web/bc-paginator';
import { BcPreloaderModule } from '@bancolombia/design-system-web/bc-preloader';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcSearchModule } from '@bancolombia/design-system-web/bc-search';
import { BcSwitchModule } from '@bancolombia/design-system-web/bc-switch';
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import { BcTabsModule } from '@bancolombia/design-system-web/bc-tabs-group';
import { BcTagModule } from '@bancolombia/design-system-web/bc-tag'
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button'

@NgModule({
    declarations: [],
    imports: [
        BcIconModule.forRoot({ path: "https://library-sdb.apps.bancolombia.com/bds/6.58.1" }),
    ],
    exports: [
        BcPaginatorModule,
        BcTableModule,
        BcTabsModule,
        BcSwitchModule,
        BcInputFileModule,
        BcSearchModule,
        BcMenuModule,
        BcHeaderModule,
        BcModalModule,
        BcRadioModule,
        BcCardModule,
        BcButtonModule,
        BcIconModule,
        BcInputSelectModule,
        BcInputModule,
        BcAccordionModule,
        BcAlertModule,
        BcCheckboxModule,
        BcPreloaderModule,
        BcLoaderModule,
        BcFooterModule,
        BcBreadcrumbModule,
        BcCalendarModule,
        BcInputDateRangeModule,
        BcInputDateModule,
        BcTagModule,
        BcIconButtonModule
    ]
})
export class BcSharedModule { }
