import {Component, Input, ViewChild, EventEmitter, Output, AfterViewInit} from '@angular/core';
import {BcModalComponent} from '@bancolombia/design-system-web/bc-modal';
import listErrors from 'src/app/core/constants/errors/generalErrors.json';
import { CapitalizePipe } from '../../pipes/capitalize/capitalize.pipe';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.css']
})

export class ErrorModalComponent implements AfterViewInit {

    @ViewChild('errorModal', {static: true}) errorModal: BcModalComponent;
    errors: any = listErrors;

    @Input()
    iconType: 'error' | 'success' | 'warning' | 'info' | 'default' = "default"

    isCloseButtonHidden: boolean = false;
    isOuterClose: boolean = true;

    isCancellable: boolean;
    txButtonRight: string;
    txButtonLeft: string;
    txTitle: string;
    txSubtitle: string;
    urlIconDefault: string;
    errorsShow: any[] = [];
    txtBtnDefault:string = 'Aceptar';

    @Output()
    outputValue = new EventEmitter();

    @Output()
    selectedOption = new EventEmitter();

    constructor(private capitalize: CapitalizePipe) {
        this.isCancellable = false;
        this.txButtonRight = this.txtBtnDefault;
        this.txTitle = this.errors.servicioNoDisponible;
        this.txSubtitle = this.errors.errorDefault;
        this.urlIconDefault = 'assets/img/idea.svg';
    }

    ngAfterViewInit(): void {
        this.errorModal.openModal();
    }

    /**
     * Evento que se ejecuta cuando presiona alguno de los botones.
     * @param event
     */
    closeModal(event: number) {
        this.errorModal.shutDown();
        
        this.selectedOption.emit(event);
        if (event === 1) {
            this.outputValue.emit();
        }
    }

    /**
     * Determina el titulo del modal.
     * @param value
     */
    @Input() set title(value: string) {
        if (value) {
            this.txTitle = value;
        } else {
            this.txTitle = this.errors.servicioNoDisponible;
        }
    }

    /**
     * Determina el subtitulo del modal.
     * @param value
     */
    @Input() set subtitle(value: string) {
        if (value) {
            this.txSubtitle = value;
        } else {
            this.txSubtitle = this.errors.errorDefault;
        }
    }

    /**
     * Define el tipo de icono a mostrar, por defecto es light
     * @param type valores permitidos: revisar src/assets/img/ el nombre de la imágen es el que se asignará;
     */
    @Input() set typeIcon(type: string) {
        if (type) {
            this.urlIconDefault = 'assets/img/' + type + '.svg';
        } else {
            this.urlIconDefault = 'assets/img/idea.svg';
        }
    }

    /**
     * Determina el texto del botón izquierdo.
     * @param value
     */
    @Input() set buttonLeft(value: string) {
        if (value) {
            this.isCancellable = true;
            this.txButtonLeft = this.capitalize.transform(value);
        } else {
            this.isCancellable = false;
        }
    }

    /**
     * Determina el texto del botón derecho.
     * @param value
     */
    @Input() set buttonRight(value: string) {
        if (value) {
            this.txButtonRight = this.capitalize.transform(value);
        } else {
            this.txButtonRight = this.txtBtnDefault;
        }
    }

    /**
     * Determina si el modal puede cerrarse.
     * @param value
     */
    @Input() set isCloseable(value: boolean) {
        if (!value) {
            this.isOuterClose = false;
            this.isCloseButtonHidden = true;
        }
    }

    @Input() set errorList(list) {
        this.errorsShow = list;
    }
}
