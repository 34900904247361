<div *ngIf="loginDisplay">
    <app-spinner></app-spinner>
    <app-header-bc [showNotification]="showNotification" (openNotifications)="eventNotification($event)">
    </app-header-bc>
    <bc-menu [logoutFunction]="logout" class="menu-app-showcase" state="close">
        <bc-menu-drawer>
            <div *ngIf="!closeSesion">
                <bc-menu-item *ngFor="let menu of menuDinamic" [routerLink]="menu.path" text="{{menu.text}}"
                    iconName="{{menu.iconName}}" id="{{menu.id}}">
                </bc-menu-item>
            </div>
        </bc-menu-drawer>
        <bc-menu-main></bc-menu-main>
        <div class="bc-content-body">
            <app-modal-notifications appClickOutside (click)="eventNotification(false)" *ngIf="showNotification">
            </app-modal-notifications>
            <router-outlet *ngIf="!isIframe"></router-outlet>
        </div>
    </bc-menu>
    <app-error-modal *ngIf="unerror" [title]="titleError" [buttonRight]="textButtonRight" [subtitle]="descriptionError" buttonLeft=""
        (outputValue)="this.unerror = false;" [errorList]="errorsShow" typeIcon="time_pic">
    </app-error-modal>
</div>