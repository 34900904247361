//ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//APP
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//LOGIN
import { AdminRoleGuardService } from 'src/app/modules/login/guards/admin-role-guard.service';
import { AsesorRoleGuardService } from 'src/app/modules/login/guards/asesor-role-guard.service';

//MSAL-ANGULAR
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG,
  MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//SHARED
import { BcServicesModule } from '@bancolombia/design-system-web/bc-services';
import { ComponentsModule } from './shared/components/components.module';
import { BcSharedModule } from './shared/bc-shared/bc-shared.module';
import { MatSharedModule } from './shared/mat-shared/mat-shared.module';
import { CommonsModule } from './shared/commons/commons.module';
import { msal } from 'src/environments/environment';
import {ErrorInterceptor} from './modules/login/interceptor/error-interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Interceptor} from './modules/login/interceptor/interceptor';
import {DirectivesModule} from './shared/directives/directives.module';
import { TimeoutInterceptor } from './modules/login/interceptor/timeout-interceptor';
import { MatDialogModule } from '@angular/material/dialog';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: msal.clientId,
      authority: `https://login.microsoftonline.com/${msal.tenant}`, 
      redirectUri: msal.redirectUri,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1/me', ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        MsalModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        BcServicesModule.forRoot(),
        CommonsModule,
        BcSharedModule,
        MatSharedModule,
        ComponentsModule,
        DirectivesModule,
        MatDialogModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AsesorRoleGuardService,
    AdminRoleGuardService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: []
})
export class AppModule { }
