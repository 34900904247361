import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-out-of-time',
  templateUrl: './out-of-time.component.html',
  styleUrls: ['./out-of-time.component.css']
})
export class OutOfTimeComponent implements OnInit {

  constructor() { /* No se realiza ninguna operación en este momento */ }

  ngOnInit(): void {
    // No se realiza ninguna operación en este momento
  }

}
