export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const NINE = 9;
export const TEN = 10;
export const ELEVEN = 11;
export const TWELVE = 12;
export const NINETEEN = 19;
export const TWENTY_FOUR = 24;
export const THIRTY_ONE = 31;
export const FIFTY = 50;
export const ONE_HUNDRED = 100;
export const ONE_HUNDRED_FIFTY = 150;
export const TWO_HUNDRED_FIFTY = 250;
export const THREE_HUNDRED = 300;
export const DAYS_OF_YEAR = 365.25;
export const FOUR_HUNDRED = 400;
export const FOUR_HUNDRED_AND_THREE = 403;
export const FOUR_HUNDRED_NINE = 409;
export const FIVE_HUNDRED = 500;
export const ONE_THOUSAND = 1000;
export const THREE_THOUSAND_SIX_HUNDRED = 3600;
export const SIXTY_THOUSAND = 60000;
export const THREE_MILLION_SIX_HUNDRED = 3600000;
export const ONE_THOUSAND_TWO_HUNDRED = 1200;
export const ONE_THOUSAND_AND_TWENTY_FOUR = 1024;
