import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string | null | undefined {
    if (!value) { return value };
    value = value.trim();
    return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
  }

}
