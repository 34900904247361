import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MsalService} from '@azure/msal-angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private msalService: MsalService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const errorStatus = 401;
        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            if (error.status === errorStatus) {
                this.msalService.logoutRedirect();
                localStorage.clear();
            }
            return throwError(error);
        }));
    }
}
