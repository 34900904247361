import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs'; import { timeout, catchError } from 'rxjs/operators';


@Injectable() export class TimeoutInterceptor implements HttpInterceptor {

    private readonly DEFAULT_TIMEOUT = 180000; // Tiempo de espera predeterminado en milisegundos

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timeoutValue = request.headers.get('timeout') || this.DEFAULT_TIMEOUT.toString();
        const timeoutMillis = parseInt(timeoutValue, 10);
        return next.handle(request).pipe(timeout(timeoutMillis), catchError((error: HttpErrorResponse) => {
            // Aquí puedes manejar el error de tiempo de espera
            return throwError({ ...error, time_out: true });
        }));
    }
}