import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AsesorRoleGuardService} from './modules/login/guards/asesor-role-guard.service';
import {MsalGuard} from '@azure/msal-angular';
import { OutOfTimeComponent } from './shared/commons/out-of-time/out-of-time.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
    },
    {
        path: 'inicio',
        canLoad: [MsalGuard],
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/home/header/header.module').then(m => m.HeaderModule)
    },
    {
        path: 'productos',
        canLoad: [AsesorRoleGuardService, MsalGuard],
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/home/components/product/product.module').then(m => m.ProductModule)
    },
    {
        path: 'consultas',
        canLoad: [MsalGuard],
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/home/components/inquiries/inquiries.module').then(m => m.InquiriesModule)
        , data: {title: 'Consultas'}
    },
    {
        path: 'ayudas',
        canLoad: [MsalGuard],
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/home/components/help/help.module').then(m => m.HelpModule)
    },
    {
        path: 'mi-gestion',
        canLoad: [MsalGuard],
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/home/components/my-management/my-management.module').then(m => m.MyManagementModule)
        , data: {title: 'Mi gestión'}
    },

    {
        path: 'noticias',
        canLoad: [MsalGuard],
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/home/components/news-and-help/news-and-help.module').then(m => m.NewsAndHelpModule)
    },
    {
        path: 'cerrar',
        component: OutOfTimeComponent,
        canLoad: [MsalGuard],
        canActivate: [MsalGuard],
     },
    {
        path: '**',
        redirectTo: 'inicio'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {
            preloadingStrategy: PreloadAllModules,
            useHash: false,
            relativeLinkResolution: 'legacy'
        }
    )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
